<template>
	<div>
		<div class="d-flex justify-content-between">
			<h1 class="page-header">
				{{
					$t('teachingCalendar.page_title')
				}}
			</h1>
			<ts-breadcrumb :items="[
						{ text: $t('toHomePage'), href: '/' },
						{
							text: $t(
								'teachingCalendar.page_title'
							),
							active: true
						}
					]" />
		</div>
		<ts-panel>
			<ts-panel-wrapper>
				<div class="row tw-mb-6">
					<div class="col-md-2 form-group tw-px-2">
						<label class="form-col-label control-label tw-mb-2 required">{{
							$t('teachingCalendar.dateRange')
						}}</label>
						<DatePicker type="daterange" v-model="dateRange" placement="bottom-end"
							:placeholder="$t('teachingCalendar.selectDateRange')" style="width: 100%" :transfer="true"
							format="dd-MM-yyyy" @on-change="dateRangeChange"></DatePicker>
					</div>
				</div>
			</ts-panel-wrapper>
			<div class="overflow-auto">
				<ts-table :columns="columns" :records="resources" :loading="loading">
					<template v-slot="{ record }">
						<td class="text-center">
							{{ record.schedule_date }}
						</td>
						<td class="text-center">
							{{ tConvert(record.time_from) + ' - ' + tConvert(record.time_to) }}
						</td>
						<td>
							{{ record.staff_name ? record.staff_name.employee_name_en : '' }}
						</td>
						<td>
							{{ record.staff_substitute_name ? record.staff_substitute_name.employee_name_en : '' }}
						</td>
						<td>
							{{ record.remark }}
						</td>
					</template>
				</ts-table>
			</div>
			<div class="tw-flex tw-items-center tw-justify-end tw-p-4">
				<ts-i-pagination v-model="pagination" @navigate="fetchData"></ts-i-pagination>
			</div>
		</ts-panel>
	</div>
</template>
  
<script>

import { mapState, mapGetters } from 'vuex'
import moment from 'moment'

export default {
	name: 'teaching-calendar',
	data() {
		return {
			loading: false,
			visible: false,
			show_edit: false,
			dateRange: [
				moment().add(-1, 'months').format('DD-MM-YYYY'),
				moment().format('DD-MM-YYYY')
			],
			columns: [
				{
					name: this.$t('teachingCalendar.date'),
					style: 'text-align:center; width:8%'
				},
				{
					name: this.$t('teachingCalendar.scheduleTime'),
					style: 'text-align:center'
				},
				{
					name: this.$t('teachingCalendar.teacher')
				},
				{
					name: this.$t('teachingCalendar.substituteFrom')
				},
				{
					name: this.$t('teachingCalendar.remark')
				}
			]
		}
	},
	computed: {
		...mapState('humanResource/teachingCalendar', ['resources', 'pagination']),
		...mapGetters('humanResource/teachingCalendar', ['tConvert'])
	},
	created() {
		this.fetchData();
	},
	methods: {
		fetchData(attributes) {
			this.loading = true
			this.$store
				.dispatch('humanResource/teachingCalendar/fetch', attributes)
				.then(() => {
					this.loading = false
				})
				.catch(error => {
					this.loading = false
					this.$toasted.error(error.message)
				})
		},
		dateRangeChange(dateRange) {
			this.dateRange = dateRange
			this.fetchData({
				dateRange: this.dateRange
			})
		}
	}
}
</script>
  